
import { defineComponent } from 'vue'
import ThankOutputSaction from '@/components/sections/ThankOutputSaction.vue'

export default defineComponent({
  name: 'Thank',
  components: {
    ThankOutputSaction
  }
})
