
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'

export default defineComponent({
  name: 'ThankOutputSections',
  components: {
    Main
  },
  methods: {
    goToHome () {
      this.$router.push('/dashboard/home')
    }
  }
})
